<template>
  <div class="main-layout">
    <sidebar
      :entity-name="entityName"
      :entity-logo="entityLogo"
      :entity-list="entityList"
      :parent-entity-name="parentEntityName"
      :parent-entity-route="parentEntityRoute"
      :items="sidebarItems"
      :is-sidebar-displayed.sync="isSidebarDisplayed"
      :current-view-route="$router.history.current.name"
    />
    <Header @showSidebar="isSidebarDisplayed = true" />
    <v-main><slot /></v-main>
  </div>
</template>

<script>
export default {
  name: 'MainLayout',
  components: {
    Sidebar: () => import('@/components/Sidebar'),
    Header: () => import('@/components/Header'),
  },
  props: {
    entityName: { type: String, required: true },
    entityLogo: { type: String, required: true },
    entityList: { type: Array, default: () => [] },
    parentEntityName: { type: String, default: null },
    parentEntityRoute: { type: Object, default: null },
    sidebarItems: { type: Array, required: true },
  },
  data() {
    return {
      isSidebarDisplayed: null,
    }
  },
}
</script>

<style lang="scss" scoped>
.main-layout {
  display: flex;
  height: 100%;
}
</style>
